.blog-page {
    padding: 20px;
    background-color: #f9f9f9;
}

.blog-page-title {
    text-align: center;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

.blog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 50px;
}

.blog-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;
    transition: transform 0.2s;
}

.blog-card:hover {
    transform: scale(1.05);
}

.blog-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.blog-title {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
}

.blog-description {
    font-size: 1em;
    padding: 0 10px;
    color: #666;
}

.blog-link {
    display: inline-block;
    margin: 15px 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1em;
}

.blog-link:hover {
    background-color: #0056b3;
}
