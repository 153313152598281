.footer {
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 30px 20px;
    font-family: Arial, sans-serif;
    /* font-size: 1rem; */
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-main {
    display: flex; /* Flexbox to align Address & Follow Us */
    justify-content: space-between; /* Space between the two sections */
    align-items: flex-start; /* Align at the top */
    flex-wrap: wrap; /* Wrap content on smaller screens */
  }
  
  .footer-column {
    flex: 1; /* Allow each column to take equal space */
    margin: 10px;
  }
  
  .footer-column h4 {
    font-size: 1.6rem;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .footer-column p,
  .footer-column a {
    font-size: 1rem;
    line-height: 1.7;
    color: #ddd;
    text-decoration: none;
  }
  
  .footer-column .footer-icon {
    margin-right: 10px;
    color: #ffffff;
    margin-left: 50px;
  }
  
  .footer-column .social-icons {
    display: flex; /* Use Flexbox to align icons horizontally */
    gap: 20px; /* Adjust the gap size as needed */
    justify-content: center; /* Center align icons horizontally */
}

.footer-column .social-icon {
    font-size: 1.5rem;
    color: #ffffff;
    transition: transform 0.3s ease;
}

.footer-column .social-icon:hover {
    transform: scale(1.2);
    color: #fff;
}

  .footer-column .social-icon:hover {
    transform: scale(1.6);
    color: #fff;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 0.8rem;
    color: #aaa;
  }
  
  .direction-link {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #28a745;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.direction-link:hover {
    background-color: #218838;
}
  
  @media (max-width: 768px) {
    .footer-main {
      flex-direction: row; /* Stack the sections vertically on small screens */
      align-items: center;
    }
  
    .footer-column {
      text-align: center;
      margin-bottom: 20px;
    }
    .footer-main {
        display: flex; /* Flexbox to align Address & Follow Us */
        justify-content: space-between; /* Space between the two sections */
        align-items: flex-start; /* Align at the top */
        flex-wrap: wrap; /* Wrap content on smaller screens */
      }
  }
  