/* Main Container */
.standee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 20px;
    background: linear-gradient(to bottom, #fafafa, #e3f2fd);
  }
  
  /* Header Section */
  .standee-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Back Button */
  .back-button {
    display: flex;
    align-items: center;
    border: none;
    background: #f0f4f8;
    color: #0077cc;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .back-button:hover {
    background: #e3f2fd;
    transform: scale(1.05);
  }
  
  .back-icon {
    margin-right: 8px;
  }
  
  /* Title */
  .standee-title {
    font-size: 24px;
    font-weight: bold;
    color: #0077cc;
    margin: 0;
  }
  
  /* Image Container */
  .image-container {
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .standee-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: contain; /* Ensures the image scales properly */
  }
  