/* General styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

/* Container for the entire component */
.parameterwise-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Title styles */
.parameterwise-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

/* Grid layout for graphs */
.graph-grid {
  display: grid;
  grid-template-columns: 1fr; /* Default: Single column */
  gap: 1rem;
}

/* Breakpoints for responsiveness */
@media (min-width: 576px) {
  .graph-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on small devices */
  }
}

@media (min-width: 992px) {
  .graph-grid {
    grid-template-columns: repeat(3, 1fr); /* Three columns on larger devices */
  }
}

@media (min-width: 1200px) {
  .graph-grid {
    grid-template-columns: repeat(4, 1fr); /* Four columns on very large screens */
  }
}

/* Individual graph container */
.graph-container {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Graph title */
.graph-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
  color: #555;
}

/* Loading message */
.loading-message {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #888;
  padding: 1rem;
}

/* Error message */
.error-message {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: red;
  padding: 1rem;
}

/* Tooltip styles for the chart */
.recharts-tooltip-wrapper {
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.85rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Chart axis labels */
.recharts-label {
  font-size: 0.8rem;
  fill: #666;
}

/* Responsive font sizes for titles and charts */
@media (max-width: 768px) {
  .parameterwise-title {
    font-size: 1.2rem;
  }

  .graph-title {
    font-size: 0.9rem;
  }

  .recharts-label {
    font-size: 0.7rem;
  }
}

/* Chart container size for responsiveness */
.graph-container div {
  width: 100%;
  height: auto;
  max-height: 300px;
  aspect-ratio: 16 / 9;
}

@media (max-width: 576px) {
  .graph-container div {
    max-height: 200px;
  }
}

/* Min/Max value display styles */
.min-max-values {
  font-size: 0.9rem;
  color: #444;
  margin-top: 0.5rem;
  text-align: center;
}

.min-max-values p {
  margin: 0.2rem 0;
  font-weight: 600;
}
