/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    background: linear-gradient(to bottom, #f8fcff, #e6f3fe); /* Light and unique gradient */
    color: #333;
  }
  
  /* Wrapper */
  .overview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 30px;
  }
  
  /* Header */
  .overview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Back Button */
  .back-button {
    display: flex;
    align-items: center;
    border: none;
    background: #d9eafc;
    color: #0073e6;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .back-button:hover {
    background: #c9ddf5;
    transform: scale(1.05);
  }
  
  .back-icon {
    margin-right: 8px;
  }
  
  /* Title */
  .overview-title {
    font-size: 24px;
    font-weight: bold;
    color: #0073e6;
    margin: 0;
  }
  
  /* Image Container */
  .image-container {
    width: 100%;
    max-width: 1200px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    /* margin-top: 80px; */

  }
  
  .responsive-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Media Queries for Responsiveness */
  
  /* Small Screens */
  @media (max-width: 768px) {
    .overview-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .overview-title {
      margin-top: 10px;
      text-align: center;
    }
    .image-container {
        width: 100%;
        max-width: 1200px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
        margin-top: 180px;
    
      }

      .overview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 100vh;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 180px;
      }
  }
  
  /* Extra Small Screens */
  @media (max-width: 480px) {
    .overview-header {
      padding: 10px;
    }
  
    .back-button {
      font-size: 14px;
      padding: 8px 12px;
    }
  
    .overview-title {
      font-size: 20px;
    }
    .image-container {
        width: 100%;
        max-width: 1200px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
        margin-top: 230px;
    
      }
      .overview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 100vh;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 180px;
      }
  }
  