/* Basic Layout */
.contaaiiner {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  .heading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter {
    flex: 1;
    margin-right: 10px;
  }
  
  .select {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .message {
    text-align: center;
    color: gray;
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  /* Chart Layout */
  .chart-contaaiiner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .chart {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .chart h3 {
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* Media Queries for small screens */
  @media (max-width: 768px) {
    .filters {
      flex-direction: column;
    }
  
    .filter {
      margin-bottom: 10px;
    }
  
    .message {
      font-size: 1rem;
    }
  }
  