  /* General Navigation Styles */
  .custom-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    padding: 10px 20px;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    box-shadow: none;
  }
  .trendannnny{
    margin-top: 300px;
  }
  /* Contact Button Styling */
  .custom-contact-button {
    background: linear-gradient(45deg, #008080, #4682b4); /* Teal to steel blue */
    color: white;
    padding: 10px 20px;
    border-radius: 30px; /* Rounded button */
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .custom-navigation .iaq-dropdown {
    display: flex;
    flex-direction: column;
    background-color: #d9e1c5;
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 5px;
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    width: 135px;
  }

  .custom-navigation .filter-section {
    margin-bottom: 10px;
  }

  .custom-navigation .filter-section label {
    font-weight: bold;
    margin-right: 5px;
  }

  .custom-navigation .filter-section select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .custom-navigation .custom-navigation-link.active {
    color: #007bff;
    font-weight: bold;
  }


  .custom-contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  }

  .custom-navigation.scrolled {
    background: rgba(0, 0, 0, 0.9); /* Dark background */
    backdrop-filter: blur(8px); /* Frosted glass effect */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); /* Elegant shadow */
    transition: background 0.3s ease, box-shadow 0.3s ease, backdrop-filter 0.3s ease;
  }

  .custom-navigation.scrolled .custom-navigation-link:hover {
    color: #ff6347; /* Highlight the text with a vibrant color */
    text-shadow: 0 0 10px rgba(255, 99, 71, 0.8); /* Glow effect */
    transition: color 0.3s ease, text-shadow 0.3s ease;
  }

  .custom-navigation.scrolled .custom-navigation-link {
    color: #ffffff; /* Change text color to white when scrolled */
  }

  /* Navigation Container */
  .custom-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* Logo */
  .custom-navigation-logo img {
    height: 50px;
    transition: transform 0.3s ease;
  }

  .custom-navigation-logo img:hover {
    transform: scale(1.1); 
  }

  /* Navigation Links */
  .custom-navigation-menu {
    list-style: none;
    display: flex;
    gap: 30px; /* Space between links */
    align-items: center;
  }

  .custom-navigation-item {
    margin: 0;
    list-style: none;

  }

  

  .custom-navigation-link {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    
  }
  

  .custom-navigation-link:hover {
    color: #ff6347; /* Coral color on hover */
    text-shadow: 0 0 10px rgba(255, 99, 71, 0.8); /* Glow effect */
  }

  .custom-dropdown-menu li {
    
    margin: 0; /* Optional: Adjusts spacing between items */
    list-style: none;

    padding: 0; /* Optional: Adjusts spacing within items */
  }
  /* Mobile Menu Toggle */
  .custom-navigation-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    height: 24px;
  }

  .custom-bar {
    width: 25px;
    height: 3px;
    background-color: rgb(125, 136, 114);
    transition: all 0.3s;
  }

  /* Mobile-Specific Styles */
  @media (max-width: 768px) {
    .custom-navigation-menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 20px;
      background: rgba(250, 223, 223, 0.95);
      flex-direction: column;
      gap: 15px;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
      animation: custom-slide-down 0.3s ease;
    }

    .custom-navigation-menu.show {
      display: flex;
    }

    .custom-navigation-toggle {
      display: flex;
    }

    .custom-navigation-item {
      margin: 10px 0;
    }

    .custom-navigation-link {
      font-size: 1.2rem;
    }
  }

  /* Dropdown Styles */
  .custom-dropdown {
    position: relative;
  }

  .custom-dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    min-width: 200px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    z-index: 1000;
    border-radius: 5px;
    padding: 10px 0;
  }

  .custom-dropdown:hover .custom-dropdown-menu,
  .custom-dropdown.open .custom-dropdown-menu {
    display: block;
  }

  .custom-dropdown-link {
    display: block;
    padding: 10px 15px;
    color: black;
    text-decoration: none;
  }

  .custom-dropdown-link:hover {
    background-color: #f1f1f1;
  }

  /* Mobile Toggle Animation */
  .custom-navigation-toggle.active .custom-bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .custom-navigation-toggle.active .custom-bar:nth-child(2) {
    opacity: 0;
  }

  .custom-navigation-toggle.active .custom-bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  /* Keyframe Animation for Dropdown */
  @keyframes custom-slide-down {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  