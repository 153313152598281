/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fc;
  color: #333;
  line-height: 1.6;
  overflow-x: hidden;
}

/* Header and Navigation */
header {
  background-color: #4a90e2;
  color: white;
  padding: 20px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}



/* Hero Section */
.hero {
  background: linear-gradient(to bottom, #4a90e2, #87cefa);
  color: white;
  text-align: center;
  padding: 80px 20px;
  animation: fadeIn 1.5s ease-in-out;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Info Cards Container */
#infocards {
  padding: 50px 20px;
  background-color: #e4fce1;
  text-align: center;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  justify-items: center;
}

.card-link {
  text-decoration: none;
}
.full-screen-section info-cards-section{
  /* background-image: url('../src/images/infocards/background.jpg'); */
}
.info-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;

}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.card-description {
  font-size: 1rem;
  color: #666;
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 40px;
}

footer a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

footer a:hover {
  color: #87cefa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .cards-container {
    grid-template-columns: 1fr;
  }
}
