/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease; /* Smooth transition for changes */
}

/* Body & Background */
body {
    background: linear-gradient(135deg, #f5f5f5, #ffffff);
    color: #333;
}

/* Container Styles */
.statistics-container {
    max-width: 980%;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    background: linear-gradient(45deg, #f9f9f9, #ffffff);
}

/* Title */
.statistics-title {
    font-size: 3rem;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-top: 50px;
}

/* bats */
.bats {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-bottom: 40px;
}

.bat {
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: linear-gradient(45deg, #008080, #4682b4);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bat.active {
    background-color: #1a78a4;
}

.bat:hover {
    background-color: #adaeb9;
    transform: scale(1.05);
}

/* Content Grid */
.content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 30px;
    padding: 10px;
}

.content-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.content-image:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Modal for Image */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.4s ease-out;
}

.modal.open {
    display: flex;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.modal-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
}
/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: linear-gradient(135deg, #ff5f5f, #ff8c8c);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  }
  
  .close-btn:hover {
    transform: scale(1.2);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #d9534f, #ff5f5f);
  }
  
  .close-btn:active {
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 95, 95, 0.5);
  }
  
/* Animation for modal fade-in */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Single Content */
.content-single {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    text-align: center;
}

.content-image-large {
    max-width: 85%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-image-large:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

/* PCA Text */
.pca-text h3 {
    font-size: 2.5rem;
    color: #3a3d97;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.pca-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    text-align: justify;
    padding: 0 15px;
}

/* Text Content */
.content-text {
    padding: 30px 40px;
    color: #555;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.content-text h2 {
    font-size: 2.2rem;
    color: #3a3d97;
    margin-bottom: 20px;
}

.content-text p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.content-text:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .statistics-title {
        font-size: 2rem;
        margin-bottom: 25px;
    }

    .bats {
        gap: 3px;
    }

    .bat {
        font-size: 1.1rem;
        padding: 12px 20px;
    }

    .content-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
    }

    .content-text h2 {
        font-size: 1.8rem;
    }

    .content-text p {
        font-size: 1rem;
    }
    .statistics-container {
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .statistics-title {
        font-size: 1.8rem;
    }

    .statistics-container {
        max-width: 100%;
        
    }
    .bat {
        font-size: 1rem;
        padding: 10px 18px;
    }

    .content-grid {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    .content-image-large {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .statistics-title {
        font-size: 1.6rem;
    }
    .statistics-container {
        max-width: 100%;
   
    }

    .bat {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .content-text p {
        font-size: 0.9rem;
    }

    .content-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }
}
