/* Global Settings */
body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to right, #e0eafc, #cfdef3);
  margin: 0;
  color: #333;
  line-height: 1.6;
  overflow-x: hidden;
}

/* Container */
.trend-analysis {
  max-width: 100%;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.trend-analysis:hover {
  transform: translateY(-5px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
}

/* Header */
.trend-header {
  text-align: center;
  margin-bottom: 40px;
}

.trend-header h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #34495e;
  margin-bottom: 20px;
  position: relative;
}

.trend-header h1::after {
  content: '';
  display: block;
  width: 120px;
  height: 5px;
  background: linear-gradient(to right, #2980b9, #8e44ad);
  margin: 20px auto 0;
  border-radius: 3px;
  animation: grow 1s ease-in-out;
}

.trend-header p {
  font-size: 1.3rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  font-weight: 400;
}

/* Tabs */
.trend-tabs {
  display: flex;
  justify-content: center; /* Center the tabs horizontally */
  gap: 20px; /* Space between tabs */
  margin: 50px auto 40px auto; /* Top and bottom margins, auto centers the block */
  width: fit-content; /* Adjust width to fit the content */
}


.tab-button {
  flex: 1;
  padding: 20px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #00796b;
  border: none;
  border-radius: 9px;
  text-align: center;
  cursor: pointer;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.tab-button i {
  font-size: 1.4rem;
  /* color: #8e44ad; */
}
/* 
.tab-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(to right, #8e44ad, #3498db);
  z-index: 0;
  transition: width 0.4s ease;
} */

.tab-button:hover::before {
  width: 100%;
  
}

.tab-button:hover {
  color: #fff;
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.4);
}

.tab-button.active {
  background-color: #1a78a4;
  color: #fff;
  font-weight: 700;
  /* box-shadow: 0 5px 20px rgba(142, 68, 173, 0.5); */
}

/* Tab Content */
.tab-content {
  padding: 30px;
  /* background: #f4f7fc; */
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.4s ease-in-out;
}

.tab-content h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  
}

.tab-content p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
}

/* Buttons */
.button-primary {
  display: inline-block;
  padding: 15px 30px;
  background: linear-gradient(to right, #8e44ad, #3498db);
  color: #fff;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  /* box-shadow: 0 10px 20px rgba(142, 68, 173, 0.4); */
}

.button-primary:hover {
  background: linear-gradient(to right, #3498db, #8e44ad);
  box-shadow: 0 15px 30px rgba(41, 128, 185, 0.5);
}

/* Responsiveness */
@media (max-width: 768px) {
  .trend-tabs {
    flex-direction: column;
    gap: 20px;
  }

  .tab-button {
    width: 100%;
  }

  .trend-header h1 {
    font-size: 2.4rem;
  }

  .trend-header p {
    font-size: 1rem;
  }

  .tab-content {
    padding: 20px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes grow {
  from {
    width: 0;
  }
  to {
    width: 120px;
  }
}
