/* Team Section */
.team-section {
    padding: 20px;
    text-align: center;
    height: 100%;
}

/* Section Title */
.team-section h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    margin-top: 40px;
}

.team-section h2::after {
    content: "";
    width: 90px;
    height: 3px;
    background: #3498db;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

/* Grid layout for team members */
.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px; /* Adjusts space between items */
    justify-items: center;
    align-items: center;
    padding: 20px;
}

/* View All Members Button */
.view-all-btn {
    font-size: 1.5rem;
    background-color: #4A90E2;
    padding: 12px 30px;
    border-radius: 30px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    width: 250px;
    margin: 30px auto 0; /* Center-align the button */
    display: block; /* Ensures the button behaves like a block element */
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border: none;
    cursor: pointer;
}

/* Hover Effects */
.view-all-btn:hover {
    background-color: #357ABD;
    transform: scale(1.05);
}

/* Team Member Cards */
.team-member {
    width: 100%;
    max-width: 250px;
    position: relative;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 0.6s forwards;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Team Member Image */
.team-image {
    width: 100%;
    max-width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    border: 3px solid transparent;
}

/* Team Member Name */
.team-member h3 {
    font-size: 1.2em;
    color: #34495e;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
}

/* Hover Effects */
.team-member:hover .team-image {
    transform: scale(1.05);
    border: 3px solid #3498db;
}

.team-member:hover {
    transform: translateY(-10px);
}

/* Animation Keyframes for Fade-in Effect */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Button Styling */
.view-all-btn {
    display: inline-block;
    padding: 12px 25px;
    font-size: 1.1em;
    background-color: #3498db;
    color: #fff;
    /* text-transform: uppercase; */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.view-all-btn:hover {
    background-color: #2980b9;
    transform: scale(1.05);
}

/* Responsive Styling */
@media (max-width: 768px) {
    .team-grid {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }

    .team-section h2 {
        font-size: 1.8em;
    }

    .team-image {
        width: 100%;
        max-width: 140px;
        height: 140px;
    }

    .team-member h3 {
        font-size: 1.1em;
    }
}

@media (max-width: 480px) {
    .team-grid {
        grid-template-columns: repeat(2, 1fr); /* Two items per row on small screens */
        gap: 15px;
    }

    .team-section h2 {
        font-size: 1.5em;
    }

    .team-image {
        width: 100%;
        max-width: 120px;
        height: 120px;
    }

    .team-member h3 {
        font-size: 1em;
    }

    .view-all-btn {
        width: 60%;
        padding: 10px;
        font-size: 1em;
    }
}
