/* Container */


.container {
    /* max-width: 800px; */
    /* margin: 0 auto; */
    padding: 20px;
    border: black;
    margin-top: 150px;
  }
  /* body {
    border: 4px solid black;
  } */
  /* Form */
  form {
    background-color: #f2fff0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: black;
    /* width: 50%; */
justify-content: center;
  }
  
  /* Heading */
  h3 {
    margin-top: 20px;
  }
  
  /* Labels */
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Inputs */
  input[type="text"],
  input[type="date"],
  input[type="number"],
  select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Checkbox */
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  /* Radio buttons */
  input[type="radio"] {
    margin-right: 5px;
  }
  
  /* Submit button */
  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    justify-content: center;
    display: block;
    margin: auto;
    /* width: 20%; */
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
  }
  
  @media only screen and (max-width: 768px) {
    /* Adjustments for tablets */
    .container {
      padding: 10px;
    }
    
    form {
      padding: 15px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    /* Adjustments for mobile */
    .container {
      padding: 5px;
    }
    
    form {
      padding: 10px;
    }
    
    /* You can add more specific adjustments for smaller screens if needed */
  }
  