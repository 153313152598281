.body{
  background-color: rgb(203, 159, 102);
}
/* Styles for the Gallery Section */
.gallery-container {
    padding: 20px;
    max-width: 100%;
    background-color: rgb(240, 251, 220);
  }
  
  .react-full-gallery{
    margin: 110px;
  }
  h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    margin-top: 50px;
  }
  
  /* Ensure gallery grid is responsive */
  .react-photo-gallery--gallery img {
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .react-photo-gallery--gallery img:hover {
    transform: scale(1.05);
  }
  
  /* Show More Button */
  .show-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #1976d2; /* Primary color */
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .show-more-button:hover {
    background-color: #155a9c; /* Darker on hover */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .gallery-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 1.5rem;
      margin-top: 70px;
    }
  
    .show-more-button {
      width: 100%;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    h2 {
      font-size: 1.2rem;
    }
  
    .show-more-button {
      font-size: 0.9rem;
    }
  }
  