/* Main container */
.graph-locations-container {
  max-width: 1400px;
  margin: 2rem auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}



/* Text content section */
.text-content {
  text-align: center;
  margin-bottom: 3rem;
  padding: 0 20px;
}

.heading {
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 1.0rem;
  font-weight: 600;
  line-height: 1.2;
  transition: color 0.3s ease;
}

.description {
  font-size: 1.2rem;
  color: #546e7a;
  margin-bottom: 1rem;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.project-details {
  font-size: 1.1rem;
  color: #607d8b;
  font-style: italic;
  line-height: 1.6;
  max-width: 900px;
  margin: 1rem auto;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #2196f3;
}

/* Graphs container */
.graphs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.map-container,
.bar-graph-container {
  flex: 1;
  margin: 10px;

}



.bar-graph-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  height: auto;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.bar-graph-container .status-box {
  width: 100px;
  height: 100px;
  background: #4caf50; /* Completed status color */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

/* Loading state */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: #2196f3;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
}

/* Error state */
.error {
  padding: 1rem;
  color: #dc3545;
  background: #fde8e8;
  border-radius: 8px;
  text-align: center;
  margin: 1rem;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
  .graph-locations-container {
    margin: 1rem;
  }

  .graphs-container {
    flex-direction: column;
    align-items: center;
  }

  .bar-graph-container {
    justify-content: center;
  }
}

/* Other styles remain unchanged */


@media screen and (max-width: 1200px) {
  .graph-locations-container {
    margin: 1rem;
  }

  .heading {
    font-size: 2rem;
  }
}

@media screen and (max-width: 992px) {


  .heading {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .project-details {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .graph-locations-container {
    margin: 0.5rem;
    padding: 15px;
  }

  .text-content {
    padding: 0 10px;
  }

  .heading {
    font-size: 1.6rem;
  }

  .graphs-container {
    gap: 1rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .heading {
    font-size: 1.4rem;
  }

  .description,
  .project-details {
    font-size: 0.95rem;
  }
  


}

/* Print styles */
@media print {
  .graph-locations-container {
    box-shadow: none;
    margin: 0;
    padding: 10px;
  }

  .graphs-container {
    gap: 1rem;
  }

  .map-container,
  .bar-graph-container {
    box-shadow: none;
    break-inside: avoid;
  }
}

/* Animation for content loading */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.graph-locations-container {
  animation: fadeIn 0.6s ease-out;
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  .graph-locations-container {
    background: white;
    border: 2px solid black;
  }

  .heading {
    color: black;
  }

  .description,
  .project-details {
    color: black;
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  .graph-locations-container,
  .map-container,
  .bar-graph-container {
    animation: none;
    transition: none;
  }

  .map-container:hover,
  .bar-graph-container:hover {
    transform: none;
  }
}


.text-content {
  text-align: center;
  margin-bottom: 20px;
}

.graphs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.map-container,
.bar-graph-container {
  flex: 1;
  margin: 10px;
}

.map-container {
  height: 50px; /* Adjust the height as needed */
}

.bar-graph-container {
  height: 500px; /* Adjust the height as needed */
}

.loader {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
}

/* Media Query for Larger Screens */
@media (min-width: 1200px) {
  .graphs-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .map-container,
  .bar-graph-container {
    height: 500px; /* Increased height for larger screens */

  }
}


.graph-locations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.text-content {
  text-align: center;
  margin-bottom: 20px;
}

.total-participants {
  font-weight: bold;
  margin-top: 10px;
}

.graphs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.map-container {
  flex: 1;
  min-width: 300px;
  margin: 10px;
}

.info-boxes-container {
  flex: 1;
  min-width: 300px;
  margin: 10px;
  display: flex;
  gap: 10px;
}

.info-box {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.info-boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  /* background-color: #ffebcc; Light background color */
  padding: 20px;
}

.info-box {
  background: #2a5dab; /* Blue background */
  color: white; /* White text */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px; /* Fixed width for uniformity */
}

.info-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-value {
  font-size: 14px;
}
.dashboard-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
  background: #fff;
  min-height: 100vh;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
}

.main-title {
  font-size: 2rem;
  color: #1a365d;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.sub-title {
  font-size: 1.25rem;
  color: #2d4a7f;
  margin-bottom: 1rem;
  font-weight: 500;
}

.project-description {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  border-left: 4px solid #3b82f6;
}

.project-description p {
  font-size: 1rem;
  color: #475569;
  line-height: 1.6;
  margin: 0;
}

.dashboard-content {
  gap: 2rem;
  grid-template-columns: 1fr;
}

.map-section {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.map-wrapper {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.stats-section {
  width: 100%;
  padding: 1rem;
}



.info-box {
  background: #5f8eb2;
  color: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.info-box:hover {
  transform: translateY(-2px);
}

.info-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.info-value {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.25rem;
  color: #3b82f6;
}

.error-message {
  padding: 1rem;
  background: #fee2e2;
  color: #dc2626;
  border-radius: 8px;
  text-align: center;
  margin: 2rem;
}

@media (min-width: 768px) {
  .dashboard-content {
    grid-template-columns: 3fr 2fr;
  }

  .main-title {
    font-size: 2.5rem;
  }

  .map-wrapper {
    height: 600px;
  }
}

@media (min-width: 1024px) {
  .dashboard-container {
    padding: 2rem;
  }

  .map-wrapper {
    height: 700px;
  }

  .stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .map-section {
    width: 50%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: 50%;
  }

  .map-wrapper {
    height: 550px;
  }
  
}

@media (max-width: 767px) {
  .dashboard-container {
    padding: 0.5rem;
  }

  .main-title {
    font-size: 1.5rem;
  }

  .sub-title {
    font-size: 1rem;
  }

  .project-description {
    padding: 0.75rem;
  }

  .project-description p {
    font-size: 0.875rem;
  }

  .map-wrapper {
    height: 300px;
  }

  .stats-grid {
    grid-template-columns: 1fr;
  }

  .info-box {
    padding: 1rem;
  }
}