/* HeroSection.css */

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and fill container */
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {

  .hero-container {
    position: relative;
    width: 100%;
    height: 50vh; /* Full viewport height */
    overflow: hidden;
  }
  .hero-video {
    object-fit: contain; /* Ensure no cropping on smaller devices */
  }
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
}

.hero-overlay h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-overlay p {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  z-index: 3;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.navbar-links li a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #ffd700;
}

.navbar-scrolled {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .hero-overlay h1 {
    font-size: 2rem;
  }

  .hero-overlay p {
    font-size: 1rem;
  }

  .navbar-links {
    flex-direction: column;
    gap: 1rem;
  }
}
