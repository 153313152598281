/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F1F8E9; /* Light Mint */
  height: 100vh;
}

/* Instruments Section */
.instruments-section {
  padding: 40px;
  background-color: #FFFFFF; /* White */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  max-width: 1200px;
  margin: 40px auto;
  overflow: hidden;
  margin-top: 80px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333333; /* Dark Charcoal */
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Back Button */
.baccck-button {
  background-color: #4C9F70; /* Soft Green */
  color: white;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  display: block;
  margin: 30px auto;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 20%;
}

.baccck-button:hover {
  background-color: #b6c8cf; /* Coral */
  transform: translateY(-3px);
}

/* Instrument Container */
.instrument-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
}

/* Instrument Item */
.instrument {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: #f9f9f9; /* Soft Grey */
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.instrument:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.instrument.reverse {
  flex-direction: row-reverse;
}

/* Image Styling */
.instrument-image {
  width: 50%;
  height: 50%;
  object-fit: contain;
  max-height: 250px;
  border-radius: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

.instrument-image:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.instrument-text {
  width: 50%;
  padding: 20px;
}

.instrument-text h5 {
  font-size: 2rem;
  font-weight: 700;
  color: #333333; /* Dark Charcoal */
  margin-bottom: 15px;
  text-transform: capitalize;
}

.instrument-text p {
  font-size: 1.1rem;
  color: #555555; /* Medium Grey */
  line-height: 1.7;
}

/* Modal Styles for Image Preview */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.open {
  display: flex;
  opacity: 1;
}

.modal-content {
  background-color: #fff;
  padding: 10px;
  max-width: 80%;
  max-height: 70%;
  overflow: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  animation: scaleUp 0.4s ease-out forwards;
}

@keyframes scaleUp {
  to {
    transform: scale(1);
  }
}

.modal-content img {
  width: 100%;
  height: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
}

.close:hover {
  color: #FF7043; /* Coral */
}

/* Responsive Design */
@media (max-width: 768px) {
  .instrument {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .instrument.reverse {
    flex-direction: column-reverse;
  }

  .instrument-image {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
  }

  .instrument-text {
    width: 90%;
  }

  .baccck-button {
    width: 40%; /* Adjust width for smaller screens */
    font-size: 1rem; /* Slightly smaller font for phone screens */
    padding: 10px 20px;
  }
}

/* Additional Media Query for Phones */
@media (max-width: 480px) {
  .instrument-text h5 {
    font-size: 1.6rem; /* Adjust title size for smaller screens */
  }

  .instrument-text p {
    font-size: 1rem; /* Adjust paragraph size */
  }

  .instrument-image {
    width: 90%;
    margin-bottom: 15px;
  }

  .baccck-button {
    width: 60%; /* Adjust button width for even smaller screens */
  }

  .section-title {
    font-size: 1.8rem; /* Smaller title on phones */
  }
}
