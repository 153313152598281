

/* Container for all cards */
.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;

}

/* Card Styling */
.info-card {
  width: 260px; /* Slightly smaller width for compact design */
  height: 320px; /* Fixed height for uniformity */
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Image Section */
.card-image {
  width: 100%;
  height: 200px; /* Larger image area */
  object-fit: cover;
}

/* Content Section */
.card-content {
  padding: 10px 15px;
  text-align: center;
}

.card-title {
  font-size: 1.3rem; /* Slightly larger font */
  font-weight: bold;
  text-transform: uppercase; /* Capitalize all letters */
  color: #2c3e50; /* Dark modern shade */
  letter-spacing: 1.2px; /* Add spacing for better readability */
  margin-bottom: 6px;
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.info-card:hover .card-title {
  color: #0dddb3; /* Highlight color on hover */
}


.card-description {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.3;
}

/* Link styling to remove default blue underline */
.card-link {
  text-decoration: none;
  color: inherit;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .info-card {
    width: 90%;
    height: auto;
  }
}
