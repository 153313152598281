body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(135deg, #e0f7fa, #00695c);
  color: #333;
  width: 100%;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #f3f9f9, #80cbc4);
  
}

.contact-card {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.contact-info {
  flex: 1;
  background: linear-gradient(135deg, #00796b, #004d40);
  color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.contact-info h2 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
}

.contact-info p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.info-item {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.info-item .icon {
  margin-right: 15px;
  font-size: 24px;
  color: #80cbc4;
}

.contact-msg-batttt {
  flex: 1;
  padding: 40px;
}

.contact-msg-batttt h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.contact-msg {
  display: flex;
  flex-direction: column;
}

.msg-group {
  position: relative;
  margin-bottom: 20px;
}

.msg-group .input-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transmsg: translateY(-50%);
  color: #888;
  font-size: 18px;
}

input,
textarea {
  width: 100%;
  padding: 12px 15px 12px 45px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s;
}

input:focus,
textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
}

textarea {
  resize: none;
  height: 120px;
}

button {
  width: 100%;
  padding: 12px;
  background: #00796b;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

button:hover {
  background: #004d40;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Card Layout Adjustment */
  .contact-card {
    flex-direction: column;
  }

  /* Contact Info Section */
  .contact-info {
    padding: 20px;
    align-items: center;
    text-align: center;
  }

  .contact-info h2 {
    font-size: 24px;
  }

  /* msg Section */
  .contact-msg-batttt {
    padding: 20px;
  }

  .contact-msg-batttt h2 {
    font-size: 22px;
  }

  .msg-group {
    margin-bottom: 15px;
  }

  input,
  textarea {
    font-size: 14px;
    padding: 10px 12px 10px 35px;
  }

  button {
    padding: 15px;
    font-size: 16px;
  }

  /* Reduce Margin for Mobile */
  .info-item {
    margin: 8px 0;
  }

  /* Input Icon Adjustments */
  .msg-group .input-icon {
    left: 10px;
  }

  .info-item .icon {
    font-size: 20px;
  }

  /* Mobile-specific Button Styling */
  button:disabled {
    background: #b0bec5;
    cursor: not-allowed;
  }
}

@media (max-width: 480px) {
    .contact-card {
        display: flex;
        flex-wrap: wrap;
        max-width: 900px;
        width: 100%;
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        margin-top: 50px;
      }

}